import React, { useEffect, useState } from 'react'
import { getVimeoThumbnail, getVimeoVideoIdFromUrl } from '../../../utils/extras';
import { useNavigate } from 'react-router-dom';
const dummyThumbnail = "https://images.unsplash.com/photo-1560493676-04071c5f467b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const SimilarVideos = ({ videoId, videoUrl, urduStage, urduTag, reload }) => {
    const [details, setDetails] = useState({
        thumbnail: null,
        time: "",
    })
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('videoUrl -> ', videoUrl)
        if (!videoUrl) return;
        const videoId = getVimeoVideoIdFromUrl(videoUrl);
        getVimeoThumbnail(videoId).then((details) => {
            if (details) {
                setDetails({
                    thumbnail: details.thumbnail,
                    time: details.time
                })
            }
        });
    }, [videoUrl]);

    const handlePlayClick = () => {
        navigate('/video-player', { state: { videoSrc: videoUrl, title: urduStage, tag: urduTag, details, videoId } });
        reload()
    };

    return (
        <>
            <div className={`bg-white p-2 rounded`}>
                <div className='relative'>
                    <video
                        onClick={handlePlayClick}
                        className={`cursor-pointer border border-gray-500 rounded-md h-48 w-full object-cover`}
                        poster={details.thumbnail || dummyThumbnail}
                    ></video>
                    <div className="play-button h-full rounded-md">
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_3103_4671)">
                                <path d="M26 4.33301C14.04 4.33301 4.33331 14.0397 4.33331 25.9997C4.33331 37.9597 14.04 47.6663 26 47.6663C37.96 47.6663 47.6666 37.9597 47.6666 25.9997C47.6666 14.0397 37.96 4.33301 26 4.33301ZM21.6666 35.7497V16.2497L34.6666 25.9997L21.6666 35.7497Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_3103_4671">
                                    <rect width="52" height="52" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>


                <h4 className={`text-base mt-2 text-right mr-2`}>{urduTag}:&nbsp;&nbsp;{urduStage}</h4>
            </div>

        </>
    )
}

export default SimilarVideos