import React, { useEffect, useRef, useState } from 'react'
import BackHeader from '../../components/BackHeader'
import { Navigate, useLocation } from 'react-router-dom';
import { getAllVideos } from '../../API';
import SimilarVideos from './components/SimilarVideos';
import { Spinner } from 'react-bootstrap';
import { useDidUpdate } from '@mantine/hooks';
import ReactGA from "react-ga4";

const formatDuration = (seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${pad(minutes)}:${pad(remainingSeconds)}`;
};

const VideoPlayer = () => {
    const location = useLocation().state;
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [loadingMore, setLoadingMore] = useState(false)
    const [videosLoaded, setVideosLoaded] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [viewCount, setViewCount] = useState(0)

    const videoElement = useRef(null);
    const [startTime, setStartTime] = useState(0);

    const sendVideoWatchedEvent = (action) => {
        const currentTime = videoElement.current.currentTime;
        if (startTime != null) {
            const watchedDuration = currentTime - startTime;
            const formattedDuration = formatDuration(Math.abs(watchedDuration));

            ReactGA.event("Video Viewed", {
                category: 'Video Viewed',
                action: action, // 'Video Paused' or 'Video Switched'
                videoId: location?.videoId,
                video_category: location?.tag,
                video_title: location?.title,
                duration: formattedDuration
            });
        } else {
            console.error('Start time not set');
        }
    };

    useEffect(() => {
        const video = videoElement.current;

        const handlePlay = () => {
            if (startTime === 0 && videoElement.current.currentTime === 0) {
                // console.log("View Count : ", viewCount + 1)
                setViewCount(viewCount + 1)
                ReactGA.event("Video View Count", {
                    category: 'Count',
                    action: "Count", // 'Video Paused' or 'Video Switched'
                    videoId: location?.videoId,
                    video_category: location?.tag,
                    video_title: location?.title,
                    view_count: viewCount + 1
                });
            }
            setStartTime(video.currentTime); // Update start time when video plays
        };

        const handlePause = () => {
            sendVideoWatchedEvent('Video Paused');
        };

        video?.addEventListener('play', handlePlay);
        video?.addEventListener('pause', handlePause);

        setLoading(true)
        if (!location) return setLoading(false)
        getAllVideos(location.tag, 1).then((response) => {
            let videos = response.data.data
            // console.log("VIDEOS - RESPONSE:::::::::::::::::", videos);
            setVideos(videos)
        }).catch((error) => {
            console.log("ERROR", error);
        }).finally(() => setLoading(false))

        return () => {
            video?.removeEventListener('play', handlePlay);
            video?.removeEventListener('pause', handlePause);
        };
    }, [refresh])

    useDidUpdate(() => {
        setLoadingMore(true)
        getAllVideos(location.tag, page).then((response) => {
            // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
            setVideos([...videos, ...response.data.data])
            if (response.data.data.length < 10) setVideosLoaded(true);
        }).catch((error) => {
            console.log("ERROR", error);
        }).finally(() => setLoadingMore(false))
    }, [page])


    const reload = () => setRefresh(!refresh)

    if (!location) return <Navigate to="/home" />;
    return (
        <>
            <div className='sticky top-0 z-10 bg-[#f4f4f4]'>
                <BackHeader name={"ویڈیو"} />
                <div className="">
                    <video
                        ref={videoElement}
                        src={location?.videoSrc}
                        className=" w-full"
                        controls
                        poster={location?.details?.thumbnail}
                        autoPlay
                    ></video>

                    <div className="mt-2 mx-3 flex flex-row-reverse justify-between items-center">
                        <h3 className="text-xl mb-3">{location?.title}</h3>
                        <p className="bg-[#d4f0e3] px-4 w-16 h-8 text-[14px] flex justify-center items-center rounded-full">{location?.tag}</p>
                    </div>
                </div>
            </div>


            {!loading ?
                <div className='p-2 flex flex-col gap-3'>
                    {
                        videos?.filter(vid => vid.id != location.videoId)?.map((item, index) => (
                            <SimilarVideos
                                key={index}
                                videoId={item.id}
                                videoUrl={item.videoUrl}
                                urduStage={item.urduStage}
                                urduTag={item.urduTag}
                                reload={reload}
                            />
                        ))
                    }
                </div>
                :
                <div className="flex justify-center items-center h-36 mt-3">
                    <Spinner />
                </div>
            }

            {!videosLoaded && videos.length > 0 && <div className='p-2'>
                {!loadingMore ?
                    <button
                        className="w-full bg-black rounded-md text-white py-2 active:translate-y-[1px]"
                        onClick={() => setPage(page + 1)}
                    >مزید لوڈ کریں</button>
                    :
                    <div className="h-10 relative">
                        <Spinner style={{ position: "absolute", top: "48%", left: "48%" }} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>}
            </div>}
        </>
    )
}

export default VideoPlayer