import { Group, NumberInput, Radio } from '@mantine/core'
import React from 'react'

const PaymentMethods = ({ paymentMethod, setPaymentmethod, cellNo, setConfirmationModalOpen, setDrawerOpen }) => {
    return (
        <div>
            <h2 className="text-right text-[20px] font-bold">ادائیگی کریں۔</h2>

            {paymentMethod && <NumberInput
                label="فون نمبر"
                placeholder="Input placeholder"
                hideControls
                defaultValue={cellNo}
                style={{ direction: "rtl" }}
                className='mt-4 digit'
                radius={12}
                classNames={{
                    label: "mb-2",
                    input: "digit"
                }}
                disabled={paymentMethod === "dcb"}
                rightSection={<svg
                    style={{
                        left: "10px",
                        right: "auto",
                    }}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_3161_10501)">
                        <path
                            d="M4.41333 7.69333C5.37333 9.58 6.92 11.12 8.80667 12.0867L10.2733 10.62C10.4533 10.44 10.72 10.38 10.9533 10.46C11.7 10.7067 12.5067 10.84 13.3333 10.84C13.7 10.84 14 11.14 14 11.5067V13.8333C14 14.2 13.7 14.5 13.3333 14.5C7.07333 14.5 2 9.42667 2 3.16667C2 2.8 2.3 2.5 2.66667 2.5H5C5.36667 2.5 5.66667 2.8 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z"
                            fill="#D3D3D3"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_3161_10501">
                            <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0 0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>}
            />}

            <Radio.Group
                name="paymentmethod"
                style={{ direction: "rtl" }}
                className="text-[18px] my-4 tracking-wider"
                value={paymentMethod}
                onChange={(value) => setPaymentmethod(value)}
            >
                <Group mt="xs">
                    {/* <Radio value="ep-jz" label="ایزی پیسہ/جاز کیش" className="border-b pb-[14px] w-full" /> */}
                    <Radio value="dcb" label="موبائل بیلنس کے ساتھ ادائیگی کریں۔" className="border-b pb-[14px] w-full" />
                </Group>
            </Radio.Group>

            {paymentMethod && <button
                className='bg-[#007F3B] w-full h-12 text-center text-white rounded-[4px] active:translate-y-[1px]'
                onClick={() => {
                    setDrawerOpen(false);
                    if (paymentMethod === "dcb") setConfirmationModalOpen(true);
                    else setConfirmationModalOpen(true);
                }}
            >ادائیگی کریں</button>}
        </div>
    )
}

export default PaymentMethods