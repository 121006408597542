import { useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PremiumPlan from "../../pages/ConfirmSubscription/components/PremiumPlan";
import { Modal } from "react-bootstrap";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [planDetailsModal, setPlanDetailsModal] = useState(false)
  const closePlanModal = () => setPlanDetailsModal(false)
  const authStore = useSelector((state) => state.auth);
  if (!authStore.authData || Object.keys(authStore.authData).length === 0) return null;
  return (
    <>
      <div className="flex justify-between items-center mt-4 mr-5 ml-5 header ">
        <button onClick={toggleSidebar} aria-label="hamburger" id="hamburger">
          <svg
            className="cursor-pointer"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#0F2532" />
            <path
              d="M14.6667 24C14.4778 24 14.3196 23.936 14.192 23.808C14.064 23.6804 14 23.5222 14 23.3333C14 23.1444 14.064 22.9862 14.192 22.8587C14.3196 22.7307 14.4778 22.6667 14.6667 22.6667H25.3333C25.5222 22.6667 25.6804 22.7307 25.808 22.8587C25.936 22.9862 26 23.1444 26 23.3333C26 23.5222 25.936 23.6804 25.808 23.808C25.6804 23.936 25.5222 24 25.3333 24H14.6667ZM14.6667 20.6667C14.4778 20.6667 14.3196 20.6027 14.192 20.4747C14.064 20.3471 14 20.1889 14 20C14 19.8111 14.064 19.6527 14.192 19.5247C14.3196 19.3971 14.4778 19.3333 14.6667 19.3333H25.3333C25.5222 19.3333 25.6804 19.3971 25.808 19.5247C25.936 19.6527 26 19.8111 26 20C26 20.1889 25.936 20.3471 25.808 20.4747C25.6804 20.6027 25.5222 20.6667 25.3333 20.6667H14.6667ZM14.6667 17.3333C14.4778 17.3333 14.3196 17.2696 14.192 17.142C14.064 17.014 14 16.8556 14 16.6667C14 16.4778 14.064 16.3193 14.192 16.1913C14.3196 16.0638 14.4778 16 14.6667 16H25.3333C25.5222 16 25.6804 16.0638 25.808 16.1913C25.936 16.3193 26 16.4778 26 16.6667C26 16.8556 25.936 17.014 25.808 17.142C25.6804 17.2696 25.5222 17.3333 25.3333 17.3333H14.6667Z"
              fill="white"
            />
          </svg>
        </button>

        {
          location.pathname === "/mandi-rates" ?
            <img
              src="/svg/logo-icon.svg"
              alt="kz-logo"
              width={40}
              className="aspect-auto"
            />
            :
            <img
              src="/webp/kz-logo-compressed.webp"
              alt="kz-logo"
              width={120}
              className="aspect-auto"
            />
        }


        <div className="flex">
          {authStore.authData.msisdn !== process.env.REACT_APP_DUMMY_NUMBER &&
            <img
              src="/hamburger/user.svg"
              width={32}
              alt="logo"
              onClick={() => navigate("/personal-info")}
              className="cursor-pointer "
            />
          }
          {/* <img
            src="/hamburger/cart.svg"
            width={32}
            alt="logo"
            className="cursor-pointer"
          /> */}
        </div>
      </div>

      <div className="navigation-header"></div>

      <Modal
        show={planDetailsModal}
        onClose={closePlanModal}
        onHide={closePlanModal}
        centered
      // className="confirmations-modal"
      >
        <Modal.Body>
          <PremiumPlan onClick={closePlanModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
