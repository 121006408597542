import React from "react";
import "./ConfirmSubscription.css";
import PremiumPlan from "./components/PremiumPlan";
import { useNavigate } from "react-router-dom";

const ConfirmSubscription = () => {
  const navigate = useNavigate()
  return (
    // <div className="confirmation-container">
    //   <div className="subscription-success-section">
    //     <div className="star-icon">
    //       <svg
    //         width="72"
    //         height="72"
    //         viewBox="0 0 72 72"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M35.1033 7.81663C35.4701 7.07346 36.5299 7.07346 36.8967 7.81663L45.0376 24.3091C45.1831 24.6039 45.4643 24.8084 45.7896 24.856L63.9962 27.5171C64.8161 27.637 65.1429 28.6448 64.5493 29.223L51.3768 42.053C51.1409 42.2827 51.0333 42.6139 51.0889 42.9384L54.1974 61.0626C54.3375 61.8796 53.48 62.5026 52.7464 62.1167L36.4655 53.5548C36.1741 53.4015 35.8259 53.4015 35.5345 53.5548L19.2536 62.1167C18.52 62.5026 17.6625 61.8796 17.8026 61.0626L20.9111 42.9384C20.9667 42.6139 20.8591 42.2827 20.6232 42.053L7.45068 29.223C6.85708 28.6448 7.18387 27.637 8.00378 27.5171L26.2104 24.856C26.5357 24.8084 26.8169 24.6039 26.9624 24.3091L35.1033 7.81663Z"
    //           fill="#FFD705"
    //         />
    //       </svg>
    //     </div>
    //     <div className="success-discription">
    //       <h4 className="text-center">سبسکرائب کرنے کے لیے آپ کا شکریہ!</h4>
    //       <p className="d-none">
    //         اب آپ پریمیم سبسکرپشن کی تمام خصوصیات اگلے مہینے کی بلنگ یعنی 18 مئی
    //         2024 تک حاصل کر سکتے ہیں۔
    //       </p>
    //     </div>
    //     <Link className="button" to="/home">ہو گیا</Link>
    //   </div>
    // </div>
    <div className="flex flex-col justify-center items-center p-3">
      <PremiumPlan onClick={() => navigate('/home')} />
    </div>
  );
};

export default ConfirmSubscription;
