import "./Videos.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpinnerComponent from "../../components/Spinner/Spinner";
import MoreVideos from "./components/MoreVideos";
import { useDebouncedValue, useDidUpdate } from "@mantine/hooks";
import { getAllVideos } from "../../API";
import cropsConfig from "../../configs/urdu.crops.config";
import Spinner from "react-bootstrap/Spinner";

const Videos = () => {
  const subscriptionStore = useSelector((state) => state.Subscription);
  const subscriberCropStore = useSelector((state) => state.CropsStore.subscriberCrops);
  const cropStore = useSelector((state) => state.CropsStore);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState(subscriberCropStore?.[0]?.cropId || "wheat")
  const [debounced] = useDebouncedValue(searchValue, 500);
  const [page, setPage] = useState(1)
  const [videosLoaded, setVideosLoaded] = useState(false)


  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    getAllVideos(debounced, page).then((response) => {
      // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
      setVideos(response.data.data)
    }).catch((error) => {
      console.log("ERROR", error);
    }).finally(() => setLoading(false))
  }, [])

  useDidUpdate(() => {
    setLoading(true)
    getAllVideos(debounced, page).then((response) => {
      // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
      setVideos([...videos, ...response.data.data])
      if (response.data.data.length < 10) setVideosLoaded(true);
    }).catch((error) => {
      console.log("ERROR", error);
    }).finally(() => setLoading(false))
  }, [page])

  useDidUpdate(() => {
    setLoading(true)
    if (debounced === "") {
      setVideosLoaded(false);
      setSearchValue(subscriberCropStore?.[0]?.cropId || "")
    }
    getAllVideos(debounced.trim(), 1).then((response) => {
      // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
      setVideos(response.data.data)
      if (response.data.data.length < 10) setVideosLoaded(true);
      else setVideosLoaded(false)
    }).catch((error) => {
      console.log("ERROR", error);
    }).finally(() => setLoading(false))
  }, [debounced])

  if (!videos) return <SpinnerComponent />
  return (
    <>

      <div className="container mt-3">

        <div className=" bg-gray-200 rounded-md flex flex-row-reverse items-center px-2" style={{ direction: "rtl" }}>
          <input
            type="text"
            className="w-full border-none bg-gray-200 text-sm focus:border-none active:outline-none focus:outline-none"
            placeholder="ویڈیو تلاش کریں"
            onChange={handleSearchChange}
          />
          <svg
            className="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3025_2391)">
              <path
                d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                fill="#0F2532"
              />
            </g>
            <defs>
              <clipPath id="clip0_3025_2391">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="my-3 mx-auto flex gap-2 w-full overflow-x-auto" style={{ direction: "rtl" }}>
          {
            // map in reverse order to show the last selected crop first
            cropStore.allCrops?.slice().reverse().map((item, index) => (
              <button
                key={index}
                className={`
                  ${searchValue === item.cropId ? "bg-black text-white" : "border"}
                    rounded-full px-4 py-2 text-sm
                    `}
                onClick={() => setSearchValue(item.cropId)}
              >
                {cropsConfig[item.cropId]}
              </button>
            ))
          }
        </div>

        <>
          {/* Videos Section */}
          <div>
            {!loading && videos.length > 0 &&
              <div className="text-right">
                <MoreVideos
                  firstVideo={true}
                  videoId={videos[0]?.id}
                  videoUrl={videos[0]?.videoUrl}
                  urduStage={videos[0]?.urduStage}
                  urduTag={videos[0]?.urduTag}
                  subscriberDetails={subscriptionStore?.subscriberDetails?.data}
                />
              </div>
            }

            {/* More Videos Section */}
            <div className="more-videos-container">
              {!loading && videos.length > 0 && <h5>ملتے جلتے ویڈیوز</h5>}

              {
                videos.length === 0 && !loading ?
                  <div className="text-gray-600 flex items-center justify-center mb-3">یہ سہولت جلد میسّر کی جائے گی۔</div>
                  :
                  videos.slice(1)?.map((item, index) => (
                    <MoreVideos
                      key={index}
                      videoId={item?.id}
                      videoUrl={item?.videoUrl}
                      urduStage={item?.urduStage}
                      urduTag={item?.urduTag}
                      subscriberDetails={subscriptionStore?.subscriberDetails?.data}
                    />
                  ))
              }
              {/* <div ref={ref} style={{ height: '20px', background: 'transparent' }} /> */}
              {!videosLoaded && videos.length > 0 && <>
                {!loading ?
                  <button
                    className="w-full bg-black rounded-md text-white py-2 mt-2 active:translate-y-[1px]"
                    onClick={() => setPage(page + 1)}
                  >مزید لوڈ کریں</button>
                  :
                  <div className="h-10 relative">
                    <Spinner style={{ position: "absolute", top: "48%", left: "48%" }} animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>}
              </>}

              {
                loading && <div className="h-10 relative">
                  <Spinner style={{ position: "absolute", top: "48%", left: "48%" }} animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }

            </div>
          </div>
        </>


      </div>

    </>
  );
};

export default Videos;
