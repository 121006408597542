import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// console.log("BASE URL:::::::::::", baseURL);

// Save Subscription Data
export const saveSubscriptionData = createAsyncThunk(
  "Subscription/saveSubscriptionData",
  async ({ inputValue, subMode, subscriptionType }) => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(
        `${baseURL}/subscribe?cellno=${inputValue}&subMode=${subMode}&subscriptionId=${subscriptionType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Save - Subscriber::::::::::::", response);
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

// Get Subscriber Details
export const getSubscriberDetails = createAsyncThunk(
  "Subscription/getSubscriberDetails",
  async ({ cellno }) => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/subscribers/${cellno}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // console.log("Subscriber Details::::::::::::", response.data.data)
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
      if (error.response.data.message === "Invalid bearer token.") {
        sessionStorage.removeItem("authToken");
        localStorage.removeItem("persist:root");
        // window.location.reload();
      }
    }
  });

// Update Subscriber Details
export const updateSubscriberDetails = createAsyncThunk(
  "Subscription/updateSubscriberDetails",
  async ({ cellno }) => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.put(
        `${baseURL}/subs-detail?cellno=${cellno}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

// Unsubscribe
export const unsubscribe = createAsyncThunk(
  "Subscription/unsubscribe",
  async (cellno) => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/subscribe`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

export const SubscriptionSlice = createSlice({
  name: "Subscription",
  initialState: {
    subscriberData: {},
    subscriberDetails: {},
    subscriptionType: 2,
    isSubscriptionLoading: false,
    isBalanceError: false,
  },
  reducers: {
    setSubscriptionType: (state, action) => {
      state.subscriptionType = action.payload;
    },
    setBalanceError: (state, action) => {
      state.isBalanceError = action.payload;
    },
    setSubcriberData: (state, action) => {
      state.subscriberDetails = action.payload
    },
    updateSubscriberLocation: (state, action) => {
      state.subscriberDetails.data = {
        ...state.subscriberDetails.data,
        province: action.payload.province,
        tehsil: action.payload.tehsil,
        district: action.payload.district,
        districtUrdu: action.payload.districtUrdu,
        tehsilUrdu: action.payload.tehsilUrdu,
        lat: action.payload.lat,
        lng: action.payload.lng
      }
    },
    // resetCropsStatsStore: (state, action) => {
    //   state.subscriberData = {};
    //   state.subscriberDetails = {};
    //   state.subscriptionType = 2;
    //   state.isSubscriptionLoading = false;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSubscriptionData.pending, (state) => {
        state.isSubscriptionLoading = true;
      })
      .addCase(saveSubscriptionData.fulfilled, (state, action) => {
        state.subscriberData = action.payload;
        state.isSubscriptionLoading = false;
      })
      .addCase(saveSubscriptionData.rejected, (state, action) => {
        state.isSubscriptionLoading = false;
      }).addCase(getSubscriberDetails.pending, (state, action) => {
        state.isSubscriptionLoading = true;
      })
      .addCase(getSubscriberDetails.fulfilled, (state, action) => {
        const data = action.payload;
        if (data) {
          state.subscriberDetails = data;
        }
        state.isSubscriptionLoading = false;
      })
      .addCase(getSubscriberDetails.rejected, (state, action) => {
        state.isSubscriptionLoading = false;
      });
  },
});

export const {
  // resetCropsStatsStore,
  setSubscriptionType,
  setBalanceError,
  setSubcriberData,
  updateSubscriberLocation
} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
