import "./CropDetailsSolution.css";
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { useLocation, useNavigate } from "react-router-dom";
import CropSolutionVideo from "./VideoComponent/CropSolutionVideo";
import LazyVideo from "../Home/components/LazyVideo";
import { Divider, Image } from "@mantine/core";
import ReactGA from "react-ga4"
import { useSelector } from "react-redux";

const CropDetailsDisease = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Images, setImages] = useState([])
  const { challenge, cropName } = location.state;
  const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;
  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    let img = JSON.parse(challenge.image)
    setImages(img)
  }, [])
  return (
    <div className="crop-details-disease-page">
      {/* Carousel Section */}
      <div className="carousel-container">
        <svg onClick={() => navigate(-1)} className="back-btn cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#0F2532" />
          <g clipPath="url(#clip0_811_8632)">
            <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_811_8632">
              <rect width="24" height="24" fill="white" transform="translate(8 8)" />
            </clipPath>
          </defs>
        </svg>

        <Carousel>
          {Images.map((image, index) => (
            <Carousel.Item key={index} interval={1000}>
              <Image
                src={`${baseURL}/uploads/${image}`}
                alt={image}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "webp/field.webp";
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="flex flex-col items-end mt-3 gap-3">
        <h2>علامات سنیں</h2>
        <audio id="myAudio" controls
          onPlay={() => {
            ReactGA.event("Audio Played", {
              category: "Audio",
              action: "Play",
              label: "Audio Play",
              cropName: cropName,
              diseaseName: challenge?.challengeName,
              user_msisdn: authStore?.authData?.msisdn || null,
            });
          }}
        >
          <source src={`${baseURL}/uploads/audio_advisory/${challenge?.audio}`} />
          Your browser does not support the audio element.
        </audio>
      </div>

      {/* Crop Disease Section */}
      <div className="crop-disease-section mt-3 leading-8" style={{ direction: "rtl" }}>
        <p className="title mb-3 tracking-wider">{challenge?.challengeName}</p>
        <p className="description tracking-wider">{challenge?.challengeDesc}</p>
      </div>

      <Divider mt={25} mx={50} />

      {/* Video Container */}
      {challenge?.videos.length > 0 && <div className="video-container">
        <p>ویڈیوز</p>

        {
          challenge?.videos.map((item, index) => (
            <LazyVideo key={index}>
              <CropSolutionVideo
                videoSrc={item?.videoUrl}
                title={item?.urduStage}
                tag={item?.urduTag}
              />
            </LazyVideo>
          ))
        }
      </div>}
    </div>
  )
}

export default CropDetailsDisease;