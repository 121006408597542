import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Get All Videos
export const getAllVideos = createAsyncThunk(
  "Videos/getAllVideos",
  async (value) => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/crops/videos?videoTag=${value || ""}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log("VIDEOS - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
    }
  });

export const VideosSlice = createSlice({
  name: "Videos",
  initialState: {
    allVideos: {},
    isVideoScreenLoading: false
  },
  reducers: {


  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVideos.pending, (state) => {
        state.isVideoScreenLoading = true;
      })
      .addCase(getAllVideos.fulfilled, (state, action) => {
        state.allVideos = action.payload;
        state.isVideoScreenLoading = false;
      })
      .addCase(getAllVideos.rejected, (state, action) => {
        state.isVideoScreenLoading = false;
      });
  },
});

export default VideosSlice.reducer;
