import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SpinnerComponent from '../../../components/Spinner/Spinner'
import { updateLocation } from '../store'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { deleteAllCrops } from '../../../API'
import { clearCrops } from '../CropSection/store'

const ChangeLocationModal = ({ handleSearchChange, handleClickLocationModal, handleUpdateLocation, searchText }) => {
    const homeCropStore = useSelector(state => state.Crops)
    const cropStore = useSelector(state => state.CropsStore)
    const authStore = useSelector(state => state.auth)
    const subscriptionStore = useSelector(state => state.Subscription)
    const [userConsentModal, setUserConsentModal] = useState(false)
    const dispatch = useDispatch()

    const dispatchLocation = (item) => {
        dispatch(updateLocation({
            msisdn: authStore?.authData?.msisdn,
            province: item?.province,
            tehsil: item?.tehsil,
            district: item?.district,
            districtUrdu: item?.districtUrdu,
            tehsilUrdu: item?.tehsilUrdu,
            lat: item?.lat,
            lng: item?.lng
        }))
    }

    useEffect(() => {
        if (Object.keys(homeCropStore.locationObject).length > 0) return;
        let item = subscriptionStore?.subscriberDetails?.data
        dispatchLocation(item)
    }, [])


    const onLocationSelect = (item) => {
        dispatchLocation(item)
    }

    const updateLocationCheck = () => {
        if (subscriptionStore?.subscriberDetails?.data?.province !== homeCropStore?.locationObject.province && cropStore?.subscriberCrops?.length > 0) {
            setUserConsentModal(true)
            return
        }
        handleUpdateLocation();
    }

    const deleteCropsAndChangeLocation = () => {
        if (authStore?.authData?.msisdn === process.env.REACT_APP_DUMMY_NUMBER) {
            dispatch(clearCrops())
            handleUpdateLocation();
            return;
        }
        deleteAllCrops(authStore?.authData?.msisdn).then(() => {
            handleUpdateLocation();
            dispatch(clearCrops())
        })
            .catch((error) => {
                console.log("Error deleting crops -> ", error)
            })
            .finally(() => {
                setUserConsentModal(false);
                handleClickLocationModal(false);
            })
    }
    return (
        <>
            <div className="change-location-modal">
                <div className="header mb-4" style={{ direction: "rtl" }}>
                    <input
                        type="text"
                        className="search-input"
                        placeholder="شہر منتخب کریں"
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                    <svg
                        className="search-icon cursor-pointer"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_3025_2391)">
                            <path
                                d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                                fill="#0F2532"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_3025_2391">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                {
                    homeCropStore.isSitesLoading ? (
                        <div className='h-[510px]'>
                            <SpinnerComponent />
                        </div>
                    ) : (
                        <>
                            <Fragment>
                                {
                                    homeCropStore?.sitesData?.length > 0 ? (
                                        <ul className="list">
                                            {
                                                homeCropStore?.sitesData?.map((item, index) => (
                                                    <li key={index} className={`cursor-pointer  ${homeCropStore?.locationObject?.tehsil === item?.tehsil ? "active-location" : "inactive-location"}`}
                                                        onClick={() => onLocationSelect(item)}>
                                                        <div className='flex flex-row-reverse justify-between items-center'>
                                                            {homeCropStore?.locationObject?.tehsil === item?.tehsil ? <img
                                                                src="/webp/green-tick.webp"
                                                                alt="tick"
                                                                className={`w-6 h-6 ml-2`}
                                                                width={30}
                                                            /> : <div></div>}
                                                            <p className={`${index === 0 && "mt-2"}`}>{item?.tehsilUrdu}</p>
                                                        </div>
                                                        <hr />
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    ) : (
                                        <div className="flex items-center justify-center mb-5 h-[420px]">Content not available</div>
                                    )
                                }
                            </Fragment>
                            <div className="btns-container">
                                <button
                                    onClick={() => handleClickLocationModal(false)}
                                    className="reject"
                                >
                                    منسوخ کریں
                                </button>
                                <button
                                    disabled={!homeCropStore?.locationObject || Object.keys(homeCropStore.locationObject).length === 0}
                                    className="approve" onClick={updateLocationCheck}>منتخب کریں</button>
                            </div>
                        </>
                    )
                }
            </div>

            {/* Confirmation dialog to remove crop */}
            <Modal
                show={userConsentModal}
                onHide={() => setUserConsentModal(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="confirmation-dialog"
            >
                <Modal.Body>
                    <div className='flex justify-center'>
                        <p className="description leading-9 w-52">یہ عمل آپ کی تمام فصلوں کو ہٹا دے گا۔</p>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                        <button onClick={() => setUserConsentModal(false)} className="w-full h-full modal-cancel-btn-black">منسوخ کریں</button>
                        <Link onClick={deleteCropsAndChangeLocation} className="w-full no-underline modal-cancel-btn text-center">ٹھیک ہے</Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeLocationModal