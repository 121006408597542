import "./ConfirmationDialog.css";
import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ConfirmationDialog = ({ show, handleClick, onHide, content }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirmation-dialog  mx-auto"
    >
      <Modal.Body>
        <div>
          <p className="dialog-title"></p>
          <p className="description tracking-tight">
            {content}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <button onClick={onHide} className="w-full modal-cancel-btn">نہیں</button>
          <Link onClick={handleClick} className=" w-full ml-3 modal-btn description-none text-center">جی ہاں</Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationDialog