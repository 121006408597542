import React from 'react'
import BackHeader from '../../components/BackHeader'
// import OldCropsSection from './components/OldCropsSection';
import NewCropsSection from './components/NewCropsSection';

const YourCrops = () => {
    return (
        <div className='bg-white min-h-screen'>
            <BackHeader name={"آپ کی فصلیں"} />
            {/* <OldCropsSection /> */}
            <NewCropsSection />
        </div>
    )
}

export default YourCrops