import React from 'react'
import { useSelector } from 'react-redux';
import { applyInterFontToDigits } from '../../CropDetails/CropDetails';
import { formatDateWithUrduMonth } from '../../../utils/extras';

const NewCropsSection = () => {
    const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;
    const cropsStore = useSelector((state) => state.CropsStore);
    const allCrops = cropsStore?.subscriberCrops;
    return (
        <>
            <div className="gap-3 px-2 py-2 grid grid-cols-2" style={{ direction: 'rtl' }}>
                {allCrops?.length > 0 ? allCrops?.map((crop, index) => (
                    <div key={index} className="flex flex-col gap-2 bg-[#f4f4f4] rounded-[8px]">
                        <img
                            src={`${baseURL}/uploads/${crop?.image}`}
                            alt={crop?.cropName}
                            className='object-cover rounded-[8px] w-full'
                        />
                        <div className=" pr-4">
                            <h4 className='py-2 text-[20px]'>{crop?.urduName}</h4>
                            <p 
                            dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(crop?.sowingDt)) }}
                            className='text-xs text-white bg-[#007F3B] rounded-[8px] h-10 w-[fit-content] px-2 pt-2'    
                                ></p>
                        </div>
                    </div>
                )) : <div className="w-full h-8 text-center">کوئی فصل شامل نہیں ہے</div>}
            </div>
        </>
    )
}

export default NewCropsSection