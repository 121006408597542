import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

export const fetchAuthData = createAsyncThunk(
  "auth/fetchAuthData",
  async (tpToken) => {
    try {
      const response = await axios.post(`${baseURL}/authentications`, {
        tpPayload: tpToken.token,
      });
      console.log("TP_TOKEN - RESPONSE::::::::::", response.data);
      const { data } = response.data;

      // console.log("TP_TOKEN - RESPONSE::::::::::", response);
      // sessionStorage.setItem("authToken", data.token);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

// Thunk to get user location
export const getUserLocation = createAsyncThunk(
  "Crops/getUserLocation",
  async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userLocation: {},
    authData: null,
    error: null,
    loading: false,
    isLocationLoading: true,
    token: null
  },
  reducers: {
    resetAuthStatsStore: (state, action) => {
      state.authData = null;
      state.loading = false;
      state.error = null;
    },
    // add reducer for token
    saveToken: (state, action) => {
      state.token = action.payload;
    },
    addMsisdn: (state, action) => {
      state.authData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuthData.fulfilled, (state, action) => {
        const { msisdn } = action.payload;
        state.authData = { msisdn };
        state.loading = false;
      })
      .addCase(fetchAuthData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(getUserLocation.pending, (state) => {
        state.isLocationLoading = true;
      })
      .addCase(getUserLocation.fulfilled, (state, action) => {
        state.userLocation = action.payload;
        state.isLocationLoading = false;
      })
      .addCase(getUserLocation.rejected, (state, action) => {
        state.locationError = action.error.message;
        state.isLocationLoading = false;
      })
    // addcase for token
  },
});

export const {
  resetAuthStatsStore,
  saveToken,
  addMsisdn
} = authSlice.actions;

export default authSlice.reducer;
