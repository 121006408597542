import React from 'react'
import './LoadingModal.css'

const LoadingModal = ({ title, description }) => {
    return (
        <div className='w-full flex flex-col items-center'>
            <div className='loader'></div>
            <p className='text-center mt-4 font-bold'>{title}</p>
            <p className='text-center text-[#7C7C7C] text-xs w-[75%] leading-7'>{description}</p>
        </div>
    )
}

export default LoadingModal