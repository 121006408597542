import { createSlice } from "@reduxjs/toolkit";

export const MandiRatesSlice = createSlice({
    name: "MandiRates",
    initialState: {
        // ========== | Data | ========== //
        mandis: [],
        rates: [],
        // ========== | selections | ========== //
        selectedCity: null,
        selectedMandi: null,
        selectedCrop: null,
        // ========== | Modals | ========== //
        isCityModal: false,
        isMandiModal: false,
        isCropModal: false,
    },
    reducers: {
        // ========== | Data | ========== //
        setMandis: (state, action) => {
            state.mandis = action.payload;
        },
        setRates: (state, action) => {
            state.rates = action.payload;
        },
        // ========== | selections | ========== //
        setCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        setMandi: (state, action) => {
            state.selectedMandi = action.payload;
        },
        setCrop: (state, action) => {
            state.selectedCrop = action.payload;
        },
        // ========== | Modals | ========== //
        setCityModal: (state, action) => {
            state.isCityModal = action.payload;
        },
        setMandiModal: (state, action) => {
            state.isMandiModal = action.payload;
        },
        setCropModal: (state, action) => {
            state.isCropModal = action.payload;
        },
    },
});

export const {
    // ========== | Data | ========== //
    setMandis,
    setRates,
    // ========== | selections | ========== //
    setCity,
    setMandi,
    setCrop,
    // ========== | Modals | ========== //
    setCityModal,
    setMandiModal,
    setCropModal,
} = MandiRatesSlice.actions;

export default MandiRatesSlice.reducer;
