import React, { useEffect, useState } from 'react'
import { getAllVideos } from '../../../API'
import LazyVideo from './LazyVideo'
import HomeVideo from '../../../components/VideoComponent/HomeVideo'
import { useDispatch, useSelector } from 'react-redux'
import { addVideos } from '../store'

const MainVideosSection = ({ crop }) => {
    const [videos, setVideos] = useState([])
    const videosStoreData = useSelector(state => state.Crops.videos)
    const dispatch = useDispatch()
    useEffect(() => {
        if (videosStoreData && videosStoreData.length === 0) {
            getAllVideos().then((response) => {
                // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
                // setVideos(response.data.data)
                dispatch(addVideos(response.data.data))
            }).catch((error) => {
                console.log("ERROR", error);
            })
        }
        getAllVideos(crop).then((response) => {
            // console.log("VIDEOS - RESPONSE:::::::::::::::::", response.data.data);
            if (response.data.data.length > 0) setVideos(response.data.data)
            else setVideos(videosStoreData)
        }).catch((error) => {
            console.log("ERROR", error);
        })
    }, [crop])
    return (
        <>
            {videos?.slice(0, 10)?.map((item, index) => (
                <LazyVideo key={index}>
                    <HomeVideo
                        videoSrc={item.videoUrl}
                        title={item.urduStage}
                        tag={item.urduTag}
                    />
                </LazyVideo>
            ))}
        </>
    )
}

export default MainVideosSection