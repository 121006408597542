import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// console.log("BASE URL:::::::::::", baseURL);

// Get Weather
export const getWeatherData = createAsyncThunk(
  "Weather/getWeatherData",
  async ({ lat, lng, province }) => {
    try {
      // console.log("WEATHER - PAYLOAD:::::::::::", lat, lng, province);
      const token = sessionStorage.getItem("authToken");

      const response = await axios.get(`${baseURL}/sites/weather/${lat}/${lng}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

      // console.log("WEATHER - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

// Get Sites
export const getSitesData = createAsyncThunk(
  "Crops/getSitesData",
  async ({ searchText, limit, offset }) => {
    try {
      // console.log("SITES - PAYLOAD:::::::::::::::::", searchText, limit, offset);
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/sites?offset=${offset}&limit=${limit}&searchText=${searchText}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      // console.log("SITES - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

// Update Site
export const updateSitesData = createAsyncThunk(
  "Crops/updateSitesData",
  async ({ locationObj }) => {
    try {
      // console.log("UpdateSitesData - PAYLOAD:::::::::::", locationObj);
      const token = sessionStorage.getItem("authToken");
      const response = await axios.put(
        `${baseURL}/subscribers`, locationObj,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("SITES - UPDATE - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

// Get Subscriber Data
export const getSubscriberData = createAsyncThunk(
  "auth/getSubscriberData",
  async ({ cellno }) => {
    try {
      // console.log("Subs - PAYLOAD:::::::::::", cellno);
      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/subscriber?cellno=${cellno}`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      // console.log("Subs - RESPONSE:::::::::::::::::", response);
      return response;
    } catch (error) {
      // console.log("ERROR", error);
    }
  });

// Get Punjabi Month
export const getPunjabiMonth = createAsyncThunk(
  "Crops/getPunjabiMonth",
  async () => {
    try {
      const token = sessionStorage.getItem("authToken");

      const response = await axios.get(`${baseURL}/sites/punjabi-month`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

      // console.log("PUNJABI MONTH - RESPONSE:::::::::::::::::", response.data);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  }
);

export const CropsSlice = createSlice({
  name: "Crops",
  initialState: {
    sitesData: [],
    videos: [],
    subscriber: {},
    cropPhases: [],
    subscriberDetails: {},
    userLocation: {},
    locationObject: {},
    todayWeather: {},
    siteObject: {},
    weatherData: {},
    isChangeLocationModal: false,
    isSubscribed: false,
    isWeatherLoading: false,
    isSitesLoading: false,
  },
  reducers: {
    setTodayWeather: (state, action) => {
      state.todayWeather = action.payload;
    },
    updateLocation: (state, action) => {
      state.locationObject = action.payload;
    },
    updateChangeLocationModal: (state, action) => {
      state.isChangeLocationModal = action.payload;
    },
    addVideos: (state, action) => {
      state.videos = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeatherData.pending, (state) => {
        state.isWeatherLoading = true;
      })
      .addCase(getWeatherData.fulfilled, (state, action) => {
        state.weatherData = action.payload;
        state.isWeatherLoading = false;
      })
      .addCase(getWeatherData.rejected, (state) => {
        state.isWeatherLoading = false;
      })
      .addCase(getSitesData.pending, (state) => {
        state.isSitesLoading = true;
      })
      .addCase(getSitesData.fulfilled, (state, action) => {
        const { data } = action.payload || { data: null };
        state.sitesData = data;
        state.isSitesLoading = false;
      })
      .addCase(getSitesData.rejected, (state) => {
        state.isSitesLoading = false;
      })
      .addCase(updateSitesData.pending, (state) => {
        state.isCropScreenLoading = true;
      })
      .addCase(updateSitesData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.siteObject = data;
        state.isCropScreenLoading = false;
      })
      .addCase(updateSitesData.rejected, (state) => {
        state.isCropScreenLoading = false;
      }).addCase(getSubscriberData.pending, (state) => {
        state.isCropScreenLoading = true;
      })
      .addCase(getSubscriberData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.subscriber = data;
        state.isCropScreenLoading = false;
      })
      .addCase(getSubscriberData.rejected, (state) => {
        state.isCropScreenLoading = false;
      })
      .addCase(getPunjabiMonth.fulfilled, (state, action) => {
        const { data } = action.payload || { data: null };
        state.punjabiMonth = data;
      })
  },
});

export const {
  updateChangeLocationModal,
  updateLocation,
  setTodayWeather,
  addVideos
} = CropsSlice.actions;

export default CropsSlice.reducer;
