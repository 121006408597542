import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
    name: 'Sidebar',
    initialState: {
        value: false,
    },
    reducers: {
        openDrawer(state) {
            state.value = true;
        },
        closeDrawer(state) {
            state.value = false;
        },
        toggle: (state) => {
            state.value = !state.value;
        },
    },
});

export const { openDrawer, closeDrawer, toggle } = sidebarSlice.actions;
export default sidebarSlice.reducer;