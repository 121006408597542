import React, { useEffect, useState } from 'react'
import '../Videos.css'
import { getVimeoThumbnail, getVimeoVideoIdFromUrl } from '../../../utils/extras';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const dummyThumbnail = "https://images.unsplash.com/photo-1560493676-04071c5f467b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

const MoreVideos = ({ firstVideo, videoId, videoUrl, urduStage, urduTag, subscriberDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState({
    thumbnail: null,
    time: "",
  })
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('videoUrl -> ', videoUrl)
    if (!videoUrl) return;
    const videoId = getVimeoVideoIdFromUrl(videoUrl);
    getVimeoThumbnail(videoId).then((details) => {
      if (details) {
        setDetails({
          thumbnail: details.thumbnail,
          time: details.time
        })
      }
    });
  }, [videoUrl]);

  const handlePlayClick = () => {
    navigate('/video-player', { state: { videoSrc: videoUrl, title: urduStage, tag: urduTag, details, videoId } });
    // if (subscriberDetails.hasService == "N" && subscriberDetails.hasGrace == "N") {
    //   setIsModalOpen(true);
    //   return;
    // }
  };

  return (
    <>
      <div className={`more-videos-card ${firstVideo && "flex flex-col gap-2"}`}>
        <div className="videos">
          <div className='relative h-48 '>
            <video
              onClick={handlePlayClick}
              className={`cursor-pointer border border-gray-500 rounded-md ${firstVideo && "h-48 w-full object-cover"}`}
              poster={details.thumbnail || dummyThumbnail}
            ></video>
            <div className="play-button h-full rounded-md">
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3103_4671)">
                  <path d="M26 4.33301C14.04 4.33301 4.33331 14.0397 4.33331 25.9997C4.33331 37.9597 14.04 47.6663 26 47.6663C37.96 47.6663 47.6666 37.9597 47.6666 25.9997C47.6666 14.0397 37.96 4.33301 26 4.33301ZM21.6666 35.7497V16.2497L34.6666 25.9997L21.6666 35.7497Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_3103_4671">
                    <rect width="52" height="52" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <h4 className={`${firstVideo && "pr-3 text-base"}`}>{urduTag}:&nbsp;&nbsp;{urduStage}</h4>
      </div>

      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onHide={() => setIsModalOpen(false)}
        size="sm"
        centered
        className="confirmations-modal"
      >
        <Modal.Body>
          <div className="flex flex-col items-center">
            <p className="description leading-[45px] tracking-wide">آپ کو یہ ویڈیو دیکھنے کے لئے سبسکرائب کرنا ہوگا</p>
            <button className="w-100 modal-btn" onClick={() => navigate('/subscription')}>ٹھیک ہے</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MoreVideos