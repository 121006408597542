import "./ConfirmationModal.css";
import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModal = ({ show, handleClick, title, description }) => {
  return (
    <Modal
      // {...props}
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="confirmations-modal"
    >
      <Modal.Body>
        <div className="flex flex-col items-center">
          <div className="flex justify-center w-full mb-2">
            <img src="/webp/blue-tick.webp" alt="tick" width={40} />
          </div>
          <p className="description leading-10 tracking-wide">
            {description}
          </p>
          <button onClick={handleClick} className="w-100 modal-btn">ٹھیک ہے</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal