import axios from 'axios';

export const API = axios.create({
    baseURL: process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT,
});

export const authentication = async (tpPayload = "", msisdn = "") => API.post(`/authentications`, { tpPayload, msisdn });
// export const authentication = async (tpPayload = "") => axios.post(`http://192.168.12.130:8000/authentications`, { tpPayload });

export const getSubscriberDetails = async (cellNo) => API.get(`/subscribers/${cellNo}`);

export const generateOTP = async (cellNo) => API.post(`/authentications/generate-otp`, { msisdn: cellNo });

export const verifyOTP = async ({ cellno, otp }) => {
    try {
        const response = await API.post(`/authentications/verify-otp`, {
            otp,
            msisdn: cellno
        });
        return response.data;
    } catch (error) {
        console.log("ERROR", error);
    }
}

// ======================== Header Enrichment API =============================
export const getHEAPI = async () => axios.get("http://he.khushaalwatan.com/")
// ======================== Header Enrichment API =============================

export const getAllVideos = async (value = "", page = 1, limit = 10) => API.get(`/crops/videos?videoTag=${value || ""}&page=${page}&limit=${limit}`);

export const getProvinces = async () => await API.get(`/sites/provinces`);
export const getDistricts = async (province) => await API.get(`/sites/${province}/districts`);
export const getTehsils = async (district) => await API.get(`/sites/${district}/tehsils`);

export const updateSubscriber = async (data) => API.put(`/subscribers`, data);
export const deleteCrop = async (cropId, cellNo) => API.delete(`/subscribers/crops/${cropId}/${cellNo}`);
export const deleteAllCrops = async (cellNo) => API.delete(`/subscribers/all-crops/${cellNo}`);

export const updateSubscription = async (packageId, data) => API.post(`/subscriptions/upgrade/${packageId}`, data);
export const subscribeUser = async (data) => API.post(`/subscriptions/subscribe`, data);

export const getPhases = async (cropId, province) => await API.get(`/crops/${cropId}/${province}/phases`);
export const getChallenges = async (phaseId) => await API.get(`/crops/phases/${phaseId}/challenges`);

export const getWeatherData = async (lat, lon) => await API.get(`/sites/weather/${lat}/${lon}`);
export const getPunjabiMonth = async () => await API.get(`/sites/punjabi-month/`);

// ============================== Crops =====================================
export const saveSubcriberCrops = async (cropsArray) => API.post('/subscribers/crops', cropsArray);
export const updateSubcriberCrop = async (cropId, cellno, sowingDate) => API.put('/subscribers/crops', { cropId, msisdn: cellno, sowingDate })


// ============================== | Madadgaar APIs | ==============================
export const getMadadgaarToken = async () => axios.get(`${process.env.REACT_APP_MADADGAR_BASE_URL}/login`, {
    auth: {
        username: process.env.REACT_APP_MADADGAAR_USERNAME,
        password: process.env.REACT_APP_MADADGAAR_PASSWORD
    }
});

export const madadgaarAPI = axios.create({
    baseURL: process.env.REACT_APP_MADADGAR_BASE_URL,
});

export const getNearestMandis = async (location) => madadgaarAPI.get(`/external/nearest_mandis?lat=${location.lat}&long=${location.lng}`);
export const getMandiRates = async (data) => madadgaarAPI.get(`/external/all_rates/${data.mandiId}?category=${data.category}`);