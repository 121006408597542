import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setCity, setCityModal } from '../store';
import { useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { getSitesData } from '../../Home/store';
import ReactGA from "react-ga4"

const CityModal = ({ cancel }) => {
    const authStore = useSelector((store) => store.auth)
    const mandiStore = useSelector((state) => state.MandiRates);
    const homeCropStore = useSelector(state => state.Crops)
    const [tempCity, setTempCity] = useState(mandiStore.selectedCity || null);

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [debounced] = useDebouncedValue(searchText, 500);

    useEffect(() => {
        dispatch(getSitesData({ searchText, limit: 10, offset: 0 }));
    }, [debounced]);

    const submit = () => {
        dispatch(setCity(tempCity));
        dispatch(setCityModal(false))
        let data = {
            label: "City Change",
            action: "Change",
            user_msisdn: authStore?.authData?.msisdn || null,
            from: mandiStore.selectedCity.districtUrdu,
            to: tempCity.districtUrdu
        }
        ReactGA.event("Mandi City Change", data);
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title className='w-full'>
                    <div className='flex justify-between text-xl'>
                        <img src="/svg/close.svg" alt="close" className='cursor-pointer' onClick={cancel} />
                        <div>شہر منتخب کریں</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='relative'>
                    <input
                        type="text"
                        className="w-full h-11 border-none rounded-full text-right pr-10 bg-gray-200 text-sm focus:border-none active:outline-none focus:outline-none"
                        placeholder="تلاش کریں"
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <img src="/svg/magnifier.svg" alt="search" className='absolute top-[10px] right-3' />
                    {/* <img src="/svg/recorder.svg" alt="search" className='absolute top-[6px] left-3' /> */}
                </div>

                <div className='w-full list-none h-96 overflow-y-auto my-1'>
                    {
                        homeCropStore?.sitesData?.length === 0 ? <div className='text-center mt-3'>کوئی شہر نہیں ملا</div> :
                            homeCropStore?.sitesData?.map((item, index) => (
                                <div
                                    key={index}
                                    className={`${tempCity?.districtUrdu === item?.districtUrdu && "text-[#546F1C]"} flex justify-end gap-2 items-center cursor-pointer text-right text-[16px] py-[12px] w-full border-b border-gray-300 pr-2`}
                                    onClick={() => setTempCity(item)}
                                >
                                    <div>{item?.districtUrdu}</div>
                                    {tempCity?.districtUrdu === item?.districtUrdu && <img src="/svg/green-tick.svg" alt="arrow" className='w-4 h-4' />}
                                </div>
                            ))
                    }
                </div>

                <div className='flex gap-3'>
                    <button
                        onClick={cancel}
                        className="border-2 border-[#546F1C] text-[#546F1C] rounded w-full h-10 active:translate-y-[1px]"
                    >
                        منسوخ کریں
                    </button>
                    <button
                        disabled={!mandiStore.selectedCity}
                        onClick={submit}
                        className={`text-white rounded w-full h-10 ${!mandiStore.selectedCity ? "cursor-not-allowed bg-gray-400" : "active:translate-y-[1px] bg-[#546F1C]"}`}
                    >
                        منتخب کریں
                    </button>
                </div>
            </Modal.Body>
        </>
    )
}

export default CityModal