import "./Verification.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../components/Modals/ConfirmationModal/ConfirmationModal";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog/ConfirmationDialog";
import { saveSubscriptionData } from "../Subscription/store";

const Verification = () => {
  const authToken = sessionStorage.getItem("authToken");

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false)
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const authStore = useSelector((store) => store.auth);
  const subscriptionStore = useSelector((store) => store.Subscription);
  // const { subscriptionType } = cropsStore || {};

  const lat = authStore?.userLocation?.latitude || 29.45975;
  const lng = authStore?.userLocation?.longitude || 71.63831;

  // const handleInputChange = (event) => {
  //   const value = event.target.value;
  //   setInputValue(value);
  //   setIsButtonDisabled(value.trim() === ""); // Disable button if input is empty
  // };

  const handleInputChange = (e) => {
    const value = e?.target.value;

    // Remove any non-digit characters
    const numericValue = value?.replace(/\D/g, '');

    if (numericValue?.length <= 11) {
      setInputValue(numericValue);

      if (numericValue?.length === 11) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (authStore?.authData?.msisdn) {
      setInputValue(authStore?.authData?.msisdn);
      setIsButtonDisabled(false);
    } else {
      handleInputChange();
    }
  }, [authStore?.authData?.msisdn]);

  const handleClick = async () => {
    dispatch(saveSubscriptionData({ inputValue, subMode: "WEBVIEW", subscriptionType: subscriptionStore?.subscriptionType }))
    setModalShow(false);
  }

  return (
    <>
      <div className="container">
        <div className="flex items-center justify-between mb-5">
          <svg onClick={() => navigate(-1)} className="cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#0F2532" />
            <g clipPath="url(#clip0_811_8632)">
              <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_811_8632">
                <rect width="24" height="24" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>

          <p className="m-0" style={{ direction: "rtl", fontSize: "20px", fontWeight: 600 }}>فون نمبر لکھیں۔</p>
        </div>
        <div className="verification-container">
          <div className="number-section">
            <h6 className="number-text">فون نمبر</h6>
            <div className="input-wrapper">
              <input
                className="number-field"
                placeholder="000 000 331 92+"
                value={inputValue}
                onChange={handleInputChange}
                maxLength={11}
              />
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3161_10501)">
                  <path
                    d="M4.41333 7.69333C5.37333 9.58 6.92 11.12 8.80667 12.0867L10.2733 10.62C10.4533 10.44 10.72 10.38 10.9533 10.46C11.7 10.7067 12.5067 10.84 13.3333 10.84C13.7 10.84 14 11.14 14 11.5067V13.8333C14 14.2 13.7 14.5 13.3333 14.5C7.07333 14.5 2 9.42667 2 3.16667C2 2.8 2.3 2.5 2.66667 2.5H5C5.36667 2.5 5.66667 2.8 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z"
                    fill="#D3D3D3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3161_10501">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="btn-section">
            {
              isButtonDisabled ?
                <button className="submit-btn" disabled={isButtonDisabled}>
                  جمع کرائیں
                </button> :
                <button onClick={() => setModalShow(true)} className="submit-btn">
                  جمع کرائیں
                </button>
            }
          </div>
        </div>
      </div>

      <ConfirmationDialog
        show={modalShow}
        handleClick={handleClick}
        onHide={() => setModalShow(false)}
        content={"کیا آپ یہ عمل انجام دینا چاہتے ہیں؟"}
        navigateTo={"/confirm-subscription"}
      />
    </>
  );
};

export default Verification;
