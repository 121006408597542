import React from 'react'
import { formatPriceStr } from '../../../utils/extras'

const Minimum = () => (
    <div className='bg-white w-full'>
        <table className='w-full text-center text-[14px]'>
            <thead>
                <tr className='bg-[#ecf9ff] h-12'>
                    <th>زیادہ سے زیادہ</th>
                    <th>کم سے کم</th>
                    <th>فصل</th>
                </tr>
            </thead>
            <tbody>
                <tr className='w-full h-12'>
                    <td className='w-[33%] pt-2'><span className='text-[14px] font-normal'>{formatPriceStr("2400")}</span></td>
                    <td>
                        <div className='bg-[#007CAB] rounded-full flex items-center justify-center text-white h-9 pt-1'>{formatPriceStr("2400")}</div>
                    </td>
                    <td className='w-[33%] pb-2'>گندم</td>
                </tr>
            </tbody>
        </table>
    </div>
)

export default Minimum