import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Get Crop Phases Solution
export const getCropPhaseSolution = createAsyncThunk(
  "CropSolution/getCropPhaseSolution",
  async ({ challengeId }) => {
    try {
      // console.log("challengeId:::::::::::::::::", challengeId);

      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/challenge-solution?challengeId=${challengeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log("SOLUTION - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  });

export const CropsSolutionSlice = createSlice({
  name: "CropSolution",
  initialState: {
    cropPhaseSolution: [],
    isCropSolutionScreenLoading: false
  },
  reducers: {


  },
  extraReducers: (builder) => {
    builder
      .addCase(getCropPhaseSolution.pending, (state) => {
        state.isCropSolutionScreenLoading = true;
      })
      .addCase(getCropPhaseSolution.fulfilled, (state, action) => {
        state.cropPhaseSolution = action.payload;
        state.isCropSolutionScreenLoading = false;
      })
      .addCase(getCropPhaseSolution.rejected, (state, action) => {
        state.isCropSolutionScreenLoading = false;
      });
  },
});

export default CropsSolutionSlice.reducer;
