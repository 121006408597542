import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

export const getCrops = createAsyncThunk(
    "Crops/getCrops",
    async ({ province }) => {
        try {
            // console.log("CROPS - PAYLOAD:::::::::::", province);
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/crops/${province}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });

            // console.log("CROPS - RESPONSE:::::::::::::::::", response.data.data);
            return response.data.data;
        } catch (error) {
            // console.log("ERROR", error);
        }
    }
);

// Get Subscriber Crops
export const getSubscriberCrops = createAsyncThunk(
    "Crops/getSubscriberCrops",
    async ({ cellno }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(
                `${baseURL}/subscribers/${cellno}/crops`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log("GetSubscriberCrops - RESPONSE:::::::::::::::::", response.data);
            return response.data.data;
        } catch (error) {
            // console.log("ERROR", error);
        }
    }
);

export const CropsSlice = createSlice({
    name: "CropsStore",
    initialState: {
        allCrops: [],
        subscriberCrops: [],
        activeCrop: null,
        activeTab: {
            eng: "crop",
            urd: "فصلیں"
        },
        selectedCrops: null,
        tempCrop: null,
        cropObject: null,
        // ========= loading states =========
        isCropsLoading: false,
        isSubscriberCropsLoading: false,
        // =========== Modals states ===========
        addCropsDrawer: false,
        sowingDateDrawer: false,
        isChangeLocationModal: false
    },
    reducers: {
        // ============= Crops =============
        setActiveCrop: (state, action) => {
            state.activeCrop = action.payload;
        },
        updateActiveCropSowingDt: (state, action) => {
            state.activeCrop.sowingDt = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setSelectedCrops: (state, action) => {
            state.selectedCrops = action.payload
        },
        setCropObject: (state, action) => {
            state.cropObject = action.payload
        },
        setTempCrop: (state, action) => {
            state.tempCrop = action.payload
        },
        updateSubcriberCrops: (state, action) => {
            state.subscriberCrops = action.payload
        },
        clearCrops: (state) => {
            state.subscriberCrops = []
            state.selectedCrops = null
            state.activeCrop = null
        },
        // ============= Loading =============
        updateSubcriberCropsLoading: (state, action) => {
            state.isSubscriberCropsLoading = action.payload
        },
        // ============= Modal and Drawers =============
        setAddCropsDrawer: (state, action) => {
            state.addCropsDrawer = action.payload
        },
        setSowingDateModal: (state, action) => {
            state.sowingDateDrawer = action.payload
        },
        setChangeLocationModal: (state, action) => {
            state.isChangeLocationModal = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            // ================== All Crops =================
            .addCase(getCrops.pending, (state) => {
                state.isCropsLoading = true;
            })
            .addCase(getCrops.fulfilled, (state, action) => {
                state.allCrops = action.payload;
                state.isCropsLoading = false;
            })
            .addCase(getCrops.rejected, (state, action) => {
                state.isCropsLoading = false;
            })
            // ================= Subscriber Crops ==============
            .addCase(getSubscriberCrops.pending, (state) => {
                state.isSubscriberCropsLoading = true;
            })
            .addCase(getSubscriberCrops.fulfilled, (state, action) => {
                state.subscriberCrops = action.payload;
                // set subscriber crops on initial render
                if (!state.selectedCrops) state.selectedCrops = action.payload
                // set active crop to first crop in the list if not already set
                if (!state.activeCrop) state.activeCrop = action.payload[0];
                state.isSubscriberCropsLoading = false;
            })
            .addCase(getSubscriberCrops.rejected, (state, action) => {
                state.isSubscriberCropsLoading = false;
            })
    },
});

export const {
    setActiveCrop,
    updateActiveCropSowingDt,
    setActiveTab,
    setSelectedCrops,
    setCropObject,
    setTempCrop,
    updateSubcriberCrops,
    clearCrops,
    updateSubcriberCropsLoading,
    setAddCropsDrawer,
    setSowingDateModal,
    setChangeLocationModal
} = CropsSlice.actions;

export default CropsSlice.reducer;