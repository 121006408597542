import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Get Crop Phases
export const getCropPhases = createAsyncThunk(
  "CropDetails/getCropPhases",
  async ({ cropId, province }) => {
    try {
      // console.log("cropId:::::::::::::::::", cropId);

      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/crops/${cropId}/${province}/phases`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log("PHASES - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  });

// Get Crop Phase Solutions
export const getCropPhaseSolutions = createAsyncThunk(
  "CropDetails/getCropPhaseSolutions",
  async ({ phaseId }) => {
    try {
      // console.log("phaseId:::::::::::::::::", phaseId);

      const token = sessionStorage.getItem("authToken");
      const response = await axios.get(`${baseURL}/crops/phases/${phaseId}/challenges`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });

      // console.log("PHASES SOLUTIONS - RESPONSE:::::::::::::::::", response);
      return response.data;
    } catch (error) {
      // console.log("ERROR", error);
    }
  });

export const CropsDetailsSlice = createSlice({
  name: "Crops",
  initialState: {
    cropPhases: [],
    cropPhaseSolutions: {},
    isCropDetailScreenLoading: false
  },
  reducers: {


  },
  extraReducers: (builder) => {
    builder
      .addCase(getCropPhases.pending, (state) => {
        state.isCropDetailScreenLoading = true;
      })
      .addCase(getCropPhases.fulfilled, (state, action) => {
        state.cropPhases = action.payload;
        state.isCropDetailScreenLoading = false;
      })
      .addCase(getCropPhases.rejected, (state, action) => {
        state.isCropDetailScreenLoading = false;
      })
      .addCase(getCropPhaseSolutions.pending, (state) => {
        state.isCropDetailScreenLoading = true;
      })
      .addCase(getCropPhaseSolutions.fulfilled, (state, action) => {
        state.cropPhaseSolutions = action.payload;
        state.isCropDetailScreenLoading = false;
      })
      .addCase(getCropPhaseSolutions.rejected, (state, action) => {
        state.isCropDetailScreenLoading = false;
      });
  },

});

// export const {

// } = CropsSlice.actions;

export default CropsDetailsSlice.reducer;
