import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import BackHeader from '../../components/BackHeader'
import { applyInterFontToDigits } from '../CropDetails/CropDetails'
import { formatDateWithUrduMonth } from '../../utils/extras'
import RatesDatatable from './components/RatesDatatable'
import { useDispatch, useSelector } from 'react-redux'
import {
    setCityModal,
    setMandiModal,
    setCropModal,
    setCrop,
    setMandi,
    setCity,
    setMandis,
    setRates
} from './store'
import { getMadadgaarToken, getMandiRates, getNearestMandis, madadgaarAPI } from '../../API'
import { Modal, Spinner } from 'react-bootstrap'
import CityModal from './modals/CityModal'
import MandiModal from './modals/MandiModal'
import CropModal from './modals/CropModal'
import UserOnboarding from './components/UserOnboarding'

// const dropDown = ["فصل", "منڈی", "شہر"]
const dropDown = [
    {
        title: "اقسام",
        name: "فصل"
    },
    {
        title: "قریبی منڈی",
        name: "منڈی"
    },
    {
        title: "آپ کا شہر",
        name: "شہر"
    } 
]

const modalsCondition = {
    "فصل": setCropModal,
    "منڈی": setMandiModal,
    "شہر": setCityModal
}

const MandiRates = () => {
    const dispatch = useDispatch();
    const openModal = (item) => dispatch(modalsCondition[item](true));
    const mandiStore = useSelector((state) => state.MandiRates);
    const subscriptionStore = useSelector((state) => state.Subscription);
    const [mandiLoader, setMandiLoader] = useState(true);
    const [cityLoader, setCityLoader] = useState(true);
    const [tableLoader, setTableLoader] = useState(true);

    // ======================== | DISPATCH CITY OF SUBSCRIBER ON INITIAL LOAD | ========================
    const dispatchCity = () => {
        let data = subscriptionStore?.subscriberDetails?.data;
        dispatch(setCity({
            tehsil: data.tehsil,
            tehsilUrdu: data.tehsilUrdu,
            province: data.province,
            district: data.district,
            districtUrdu: data.districtUrdu,
            lat: data.lat,
            lng: data.lng
        }))
        setCityLoader(false);
    }

    useLayoutEffect(() => {
        // ======================== | LOGIN MADADGAAR | ========================
        let madadgaarToken = sessionStorage.getItem('madadgaarToken');
        if (madadgaarToken) {
            let token = sessionStorage.getItem('madadgaarToken');
            madadgaarAPI.defaults.headers.common['x-authorization-token'] = token;
            return;
        }
        // ======================== | FETCH MADADGAAR TOKEN | ========================
        getMadadgaarToken().then((res) => {
            let token = res.headers['x-authorization-token'];
            sessionStorage.setItem('madadgaarToken', token);
            // ======================== | SET MADADGAAAR TOKEN IN AXIOS | ========================
            madadgaarAPI.defaults.headers.common['x-authorization-token'] = token;
            // ======================== | DISPATCH CITY WHEN TOKEN IS SUCCESSFULLY FETCHED | ========================
            dispatchCity();
        }).catch((err) => {
            console.log('Error:', err.response ? err.response.data : err.message);
        });
    }, []);

    useEffect(() => {
        let madadgaarToken = sessionStorage.getItem('madadgaarToken');
        if (!madadgaarToken) return;
        // ======================== | DISPATCH CITY WHEN CITY IS NOT SELECTED AND TOKEN IS AVAILABLE | ========================
        if (!mandiStore.selectedCity) {
            dispatchCity();
            return;
        }
        setCityLoader(false);
    }, [])

    useEffect(() => {
        // ======================== | FETCH NEAREST MANDIS IF CITY IS SELECTED | ========================
        if (!mandiStore.selectedCity) {
            return;
        }
        !mandiLoader && setMandiLoader(true);
        setTableLoader(true);
        let location = {
            lat: mandiStore.selectedCity.lat,
            lng: mandiStore.selectedCity.lng
        };
        // ======================== | FETCH NEAREST MANDIS ACCORDING TO CITY SELECTED | ========================
        getNearestMandis(location).then((res) => {
            // ======================== | SET MANDIS IN STORE AND SELECT FIRST MANDI | ========================
            dispatch(setMandis(res.data.data));
            dispatch(setMandi(res.data.data[0]));
        }).catch((err) => {
            console.log('Error:', err.response ? err.response.data : err.message);
        }).finally(() => {
            setMandiLoader(false);
        });
    }, [mandiStore.selectedCity]);

    useEffect(() => {
        // ======================== | DISPATCH FRUIT IF CROP IS NOT SELECTED | ========================
        if (!mandiStore.selectedCrop) {
            dispatch(setCrop({
                "urdu": "سبزیاں",
                "english": "Vegetable"
            }));
        }
        // ======================== | FETCH MANDI RATES IF CROP, MANDI AND CITY ARE SELECTED | ========================
        if (mandiStore.selectedCrop && mandiStore.selectedMandi && mandiStore.selectedCity) {
            !tableLoader && setTableLoader(true);
            let data = {
                mandiId: mandiStore.selectedMandi[0],
                category: mandiStore.selectedCrop?.english
            };
            // ======================== | FETCH MANDI RATES OF SELECTED CROP AND MANDI | ========================
            getMandiRates(data).then((res) => {
                // ======================== | SET RATES IN STORE | ========================
                dispatch(setRates(res.data.data));
            }).catch((err) => {
                console.log('Error:', err.response ? err.response.data : err.message);
            }).finally(() => setTableLoader(false));
        }
    }, [mandiStore.selectedCrop, mandiStore.selectedMandi])

    const cancelCityModal = () => dispatch(setCityModal(false));
    const cancelMandiModal = () => dispatch(setMandiModal(false));
    const cancelCropModal = () => dispatch(setCropModal(false));

    return (
        <>
            <div className='px-3 pt-3 pb-20 flex flex-col gap-3'>
                {/* ============================== | Header | ============================== */}
                <p className="text-right w-full text-[20px] font-bold m-0">منڈی ریٹ</p>

                {/* ============================== | Dropdowns | ============================== */}
                <div className='flex justify-between gap-3'>
                    {
                        dropDown.map((item, index) => (
                            <div
                                key={index}
                                className='w-full'
                            >
                                <p className='text-right'>{item.title}</p>
                                {
                                    cityLoader && item.name === "شہر" ? <div key={index} className="flex justify-center items-center rounded-xl bg-white h-[52px] w-full">
                                        <Spinner size='md' />
                                    </div>
                                        : mandiLoader && item.name === "منڈی" ? <div key={index} className="flex justify-center items-center rounded-xl bg-white h-[52px] w-full">
                                            <Spinner size='md' />
                                        </div> :
                                            <div
                                                className="cursor-pointer w-full border border-gray-600 bg-white rounded-xl h-[52px] flex justify-around items-center"
                                                onClick={() => openModal(item.name)}
                                            >
                                                <img src="/svg/down-arrow.svg" alt="dropdown" />
                                                <ItemDisplay item={item.name} mandiStore={mandiStore} />
                                            </div>
                                }
                            </div>
                        ))
                    }
                </div>

                <div className='flex justify-between items-center'>
                    <p className='date text-[#777777]' style={{ direction: "rtl" }} dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(new Date())) }}></p>
                    <p className='text-lg font-bold'>قریبی منڈی</p>
                </div>

                {/* ============================== | Mandi Rates Data table | ============================== */}
                <RatesDatatable data={mandiStore.rates} loading={tableLoader} />

                {/* ============================== | Alerts | ============================== */}
                <div className='text-right text-sm flex flex-row-reverse px-3 pt-3 h-12 gap-2 bg-[#FAF0E6] rounded-lg'><p className='font-bold text-base'>:کم سے کم</p> فصل کی کم از کم قیمت ہو سکتی ہے۔</div>
                <div className='text-right text-sm flex flex-row-reverse px-3 py-3 gap-2 bg-[#FAF0E6] rounded-lg'><p className='font-bold text-base -mt-2 w-[81px]'>:زیادہ سے زیادہ</p> فصل کی زیادہ سے زیادہ قیمت ہو سکتی  ہے۔</div>

                <div className='bg-[#EFFFFF] px-3 rounded-lg flex flex-col items-end'>
                    <div className='flex items-center font-bold text-lg gap-2'>
                        <p className='text-[#007CAB]'>دستبرداری</p>
                        <img src="/svg/blue-info.svg" alt="mandi-rates" className='w-4 h-4' />
                    </div>
                    <p className='text-right leading-9'>زیادہ تر فصلوں  کی قیمتوں کا اندازہ پیشن گوئی پر لگایاجاتا
                        ہے  یہ حقیقی وقت کے ساتھ مختلف ہو سکتا ہے۔</p>
                </div>
            </div>

            {/* ============================== | Selection Modals | ============================== */}
            {/* ===================== | City Modal | ===================== */}
            <Modal
                show={mandiStore.isCityModal}
                onClose={cancelCityModal}
                onHide={cancelCityModal}
                centered
            >
                <CityModal cancel={cancelCityModal} />
            </Modal>
            {/* ===================== | Mandi Modal | ===================== */}
            <Modal
                show={mandiStore.isMandiModal}
                onClose={cancelMandiModal}
                onHide={cancelMandiModal}
                centered
            >
                <MandiModal cancel={cancelMandiModal} />
            </Modal>
            {/* ===================== | Crop Modal | ===================== */}
            <Modal
                show={mandiStore.isCropModal}
                onClose={cancelCropModal}
                onHide={cancelCropModal}
                centered
            >
                <CropModal cancel={cancelCropModal} />
            </Modal>

            {/* ===================== | User Onboarding | ===================== */}
            {!subscriptionStore?.subscriberDetails?.data?.mandiDemo && <UserOnboarding />}
        </>
    )
}

// ============================== | Helper Components FOR DISPLAYING SELECTED ITEM | ==============================
const ItemDisplay = ({ item, mandiStore }) => {
    const displayText = useMemo(() => {
        switch (item) {
            case 'شہر':
                return mandiStore.selectedCity?.districtUrdu || item;
            case 'منڈی':
                return mandiStore?.selectedMandi?.[1] || item;
            case 'فصل':
                return mandiStore?.selectedCrop?.urdu || item;
            default:
                return item;
        }
    }, [item, mandiStore.selectedCity, mandiStore.selectedMandi, mandiStore.selectedCrop]);

    return <div>{displayText}</div>;
};

export default MandiRates