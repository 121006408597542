// // ** Redux Imports
// import rootReducer from "./rootReducer";
// import { configureStore } from "@reduxjs/toolkit";

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware({
//       serializableCheck: false,
//     });
//   },
// });

// // export default store;
// export { store };

// store.js
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Choose your storage engine
import rootReducer from './rootReducer'; // Import your root reducer
import { configureStore } from '@reduxjs/toolkit';
const persistConfig = {
  key: 'root',
  storage,
  // Specify the reducers you want to persist
  whitelist: [
    "Crops",
    "CropsStore",
    "auth",
    "CropDetails",
    "CropSolution",
    "Subscription",
    "CheckUser",
    "Videos",
    "OTP",
    "Sidebar",
    "MandiRates",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Turn off serializableCheck middleware to allow non-serializable values in the state
    }),
});

const persistor = persistStore(store);

export { store, persistor };