import { DataTable } from 'mantine-datatable'
import React, { useEffect } from 'react'
import units from '../jsons/units.json'
import prediction from '../jsons/prediction.json'
import { Spinner } from 'react-bootstrap'
import { formatPriceStr } from '../../../utils/extras'

const columns = [
    {
        "accessor": "Prediction",
        "textAlign": "center",
        "width": 170,
        "title": "پیشن گوئی",
        render: (row) => <div className='flex items-center justify-center gap-2'>
            {prediction[row.Prediction]?.text ? <p className={`${prediction[row.Prediction]?.style}`}>{prediction[row.Prediction]?.text}</p> : <p className='text-2xl'>-</p>}
            {prediction[row?.Prediction]?.icon && (prediction[row?.Prediction]?.icon === "↔" ? <p>↔</p> : <img src={`/svg/${prediction[row?.Prediction]?.icon}`} alt="up-arrow" className='mt-2 w-4 h-4' />)}
        </div>
    },
    {
        "accessor": "Units",
        "textAlign": "center",
        "width": 130,
        "title": "یونٹس",
    },
    {
        "accessor": "Maximum",
        "textAlign": "center",
        "width": 130,
        "title": "زیادہ سے زیادہ",
    },
    {
        "accessor": "Minimum",
        "textAlign": "center",
        "width": 130,
        "title": "کم سے کم",
        render: (row) => <div className='bg-[#007CAB] rounded-full flex items-center justify-center text-white h-9 pt-1'>{row.Minimum}</div>
    },
    {
        "accessor": "crop",
        "textAlign": "center",
        "width": 100,
        "title": "فصل",
        render: (row) => <div className='h-14 flex justify-center items-center leading-7 tracking-wide'>{row.crop}</div>
    }
]

const RatesDatatable = ({ data, loading }) => {
    const viewportRef = React.useRef(null);
    // ===================== | Scroll to most right column | ================== //
    const scrollToColumn = (selector) => {
        const column = document.querySelector(selector);
        if (column && column?.offsetLeft + column?.offsetWidth > viewportRef.current.offsetWidth) {
            viewportRef.current.scrollLeft = column?.offsetLeft + column?.offsetWidth - viewportRef.current.offsetWidth;
        }

    };
    useEffect(() => {
        scrollToColumn(`[data-record-index="${0}"]`);
    }, [loading])
    if (loading) return <div className="flex justify-center rounded bg-white h-[500px] mt-3 pt-9">
        <Spinner />
    </div>
    return (
        <DataTable
            scrollViewportRef={viewportRef}
            customRowAttributes={({ crop }, recordIndex) => ({
                'data-crop': crop,
                'data-record-index': recordIndex,
            })}
            height={500}
            withTableBorder
            pinLastColumn
            columns={columns}
            records={data.map((record, index) => ({
                id: index,
                crop: record.ItemName,
                Minimum: formatPriceStr(record.MinRate),
                Maximum: formatPriceStr(record.MaxRate),
                Units: units[record.UnitName],
                Prediction: record.Prediction,
            }))}
            styles={{
                header: {
                    height: '48px',
                    lineHeight: '40px',
                    backgroundColor: '#ecf9ff',
                    fontSize: '14px',
                },
            }}
            // rowStyle={(record) => (record.id !== 0 && { filter: 'blur(4px)' })}
        />
    )
}

export default RatesDatatable