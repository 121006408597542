import "./CheckUser.css";
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/Modals/ConfirmationDialog/ConfirmationDialog';
import { HandleCheckSubscriber, saveUserMSISDN } from "./store";
import { addMsisdn, fetchAuthData, saveToken } from "../../redux/auth";
import { generateOTP } from "../OTP/store";
import { API } from "../../API";
import BackHeader from "../../components/BackHeader";
import { NumberInput } from "@mantine/core";

const CheckUser = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false)
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const authData = useSelector((store) => store.auth?.authData);

  // let [searchParams, setSearchParams] = useSearchParams();
  let [error, setError] = useState(false);

  const handleInputChange = (e) => {
    if (error) setError(false);
    const value = e;

    // Remove any non-digit characters
    // const numericValue = value?.replace(/\D/g, '');

    if (value?.length <= 11) {
      setInputValue(value);

      if (value?.length === 11) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  };

  const handleClick = async () => {
    try {
      // console.log("INPUT VALUE -->> ", inputValue)
      // const { data } = await dispatch(HandleCheckSubscriber({ msisdn: inputValue })).unwrap();
      // const authData = await dispatch(fetchAuthData(data)).unwrap();
      // dispatch(saveToken(authData?.accessToken));
      if (inputValue) {
        // console.log("MSISDN: ", authData?.msisdn)
        dispatch(saveUserMSISDN(inputValue));
        dispatch(addMsisdn({ msisdn: inputValue }));
        // console.log('Auth Data:', authData);
        const res = await dispatch(generateOTP({ cellno: inputValue, token: authData?.accessToken })).unwrap();
        // console.log("OTP Response: ", res)
        if (res?.success) {
          API.defaults.headers.common["Authorization"] = `Bearer ${authData?.accessToken}`;
          navigate("/verification/otp-process");
        }
        navigate("/verification/otp-process");
      }
      setModalShow(false);
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };

  // useEffect(() => {
  //   let tpPayload = searchParams.get("tp_payload");
  //   if (tpPayload !== null) {
  //     tpPayload = encodeURIComponent(tpPayload)
  //     dispatch(fetchAuthData(tpPayload)).unwrap();
  //     if (authData?.msisdn) {
  //       dispatch(saveUserMSISDN(authData?.msisdn));
  //       dispatch(generateOTP({ cellno: authData?.msisdn }))
  //       navigate("/verification/otp-process");
  //     }
  //   }
  // }, []);

  const checkNumberInRange = (number) => {
    let numStr = String(number);
    if (numStr.startsWith("034") && numStr.length > 3 && '0123456789'.includes(numStr[3])) {
      return true;
    } else {
      return false;
    }
  }

  const result = checkNumberInRange(inputValue)

  const handleBtnClick = () => {
    if (result) {
      setModalShow(true);
    } else {
      setError(true);
    }
  }
  return (
    <div className="bg-[#f8fbea] h-screen">
      {/* <br />
      <BackHeader name={"فون نمبر لکھیں۔"} />
      <br />
      <div className="container">
        <div className="check-user-container">
          <div className="number-section">
            <h6 className="number-text">فون نمبر</h6>
            <div className="input-wrapper">
              <input
                className="number-field digit"
                placeholder="0300 000 0000"
                value={inputValue}
                onChange={handleInputChange}
                maxLength={11}
              />
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3161_10501)">
                  <path
                    d="M4.41333 7.69333C5.37333 9.58 6.92 11.12 8.80667 12.0867L10.2733 10.62C10.4533 10.44 10.72 10.38 10.9533 10.46C11.7 10.7067 12.5067 10.84 13.3333 10.84C13.7 10.84 14 11.14 14 11.5067V13.8333C14 14.2 13.7 14.5 13.3333 14.5C7.07333 14.5 2 9.42667 2 3.16667C2 2.8 2.3 2.5 2.66667 2.5H5C5.36667 2.5 5.66667 2.8 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z"
                    fill="#D3D3D3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3161_10501">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            {
              error ? (
                <p style={{ direction: "rtl" }} className="text-red-500 mt-2 text-[14px]">یہ پیشکش صرف ٹیلی نار صارفین کے لیے ہے۔</p>
              ) : (
                ""
              )
            }
          </div>
          <div className="btn-section">
            {
              isButtonDisabled ?
                <button className="submit-btn" disabled={isButtonDisabled}>
                  جمع کرائیں
                </button> :
                <button onClick={() => handleBtnClick()} className="submit-btn">
                  جمع کرائیں
                </button>
            }
          </div>
        </div>
      </div> */}

      <div className="flex flex-col items-center pt-16 px-4">
        <img
          src='kz-logo2.png'
          alt='kz-logo'
          className='w-48'
        />

        <div className="w-full text-right mt-8">
          <p className="text-[20px]">خوشحال وطن میں خوش آمدید</p>
          <p className="text-[16px] text-[#777777]">سائن ان کریں۔</p>

          <div className="mt-8 w-full flex flex-col gap-4">
            <NumberInput
              label={<p className="text-[16px]">فون نمبر درج کریں۔</p>}
              placeholder="03450000000"
              hideControls
              style={{
                direction: "rtl",
              }}
              styles={{
                input: {
                  textAlign: "right",
                  direction: "rtl",
                  height: 52,
                }
              }}
              value={inputValue}
              onChange={handleInputChange}
              maxLength={11}
              error={error ? <p className="mb-0 mt-2">یہ پیشکش صرف ٹیلی نار صارفین کے لیے ہے۔</p> : ""}
              radius={12}
              leftSection={<svg
                style={{
                  left: "auto",
                  right: "10px",
                  rotate: "250deg",
                }}
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3161_10501)">
                  <path
                    d="M4.41333 7.69333C5.37333 9.58 6.92 11.12 8.80667 12.0867L10.2733 10.62C10.4533 10.44 10.72 10.38 10.9533 10.46C11.7 10.7067 12.5067 10.84 13.3333 10.84C13.7 10.84 14 11.14 14 11.5067V13.8333C14 14.2 13.7 14.5 13.3333 14.5C7.07333 14.5 2 9.42667 2 3.16667C2 2.8 2.3 2.5 2.66667 2.5H5C5.36667 2.5 5.66667 2.8 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z"
                    fill="#D3D3D3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3161_10501">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>}
            />

            {
              isButtonDisabled ?
                <button className="bg-[#9f9d9d6e] w-full py-3 rounded text-[#aeaeae] text-[18px] font-bold cursor-not-allowed" disabled={isButtonDisabled}>
                  OTP کوڈ بھیجیں
                </button> :
                <button
                  onClick={handleBtnClick}
                  className="bg-[#007F3B] w-full py-3 rounded text-white active:translate-y-[1px] text-[18px] font-bold"
                >OTP کوڈ بھیجیں</button>
            }

            <p className="text-[12px]">بہتر رہنمائی اور معلومات محفوظ کرنے کے لیے اپنا نمبر درج کریں۔</p>
          </div>
        </div>

      </div>

      <ConfirmationDialog
        show={modalShow}
        handleClick={handleClick}
        onHide={() => setModalShow(false)}
        content={"کیا آپ یہ عمل انجام دینا چاہتے ہیں؟"}
        navigateTo={"/verification/otp-process"}
      />
    </div>
  )
}

export default CheckUser