import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const BackHeader = ({ name }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (
        <div className="flex justify-between p-2">
            {!pathname.includes("verification") && <svg
                onClick={() => navigate(-1)}
                className="cursor-pointer"
                width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#0F2532" />
                <g clipPath="url(#clip0_811_8632)">
                    <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_811_8632">
                        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>}
            <p className="text-right w-full text-[20px] font-bold m-0">{name}</p>
        </div>
    )
}

export default BackHeader