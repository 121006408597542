import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Generate OTP
export const generateOTP = createAsyncThunk(
  "OTP/generateOTP",
  async ({ cellno, token }, { dispatch }) => {
    try {
      // console.log("TOKEN", token)
      // const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${baseURL}/authentications/generate-otp`, {
          msisdn: cellno
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("GET - OTP::::::::::::", response);
      // if (response?.data?.success) {
      //   dispatch(setOTPTimer(false));
      // }
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

// Verify OTP
export const verifyOTP = createAsyncThunk(
  "OTP/verifyOTP",
  async ({ cellno, otp }) => {
    try {
      const response = await axios.get(`${baseURL}/verify-otp?otp=${otp}&cellno=${cellno}`);
      return response.data;
    } catch (error) {
      console.log("ERROR", error);
    }
  }
);

export const OTPSlice = createSlice({
  name: "OTP",
  initialState: {
    generatedOTP: {},
    verifiedOTP: {},
    otpTimerCheck: true,
    isOTPLoading: false,
    isVerifyOTPLoading: false
  },
  reducers: {
    setOTPTimer: (state, action) => {
      state.otpTimerCheck = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateOTP.pending, (state) => {
        state.isOTPLoading = true;
      })
      .addCase(generateOTP.fulfilled, (state, action) => {
        state.generatedOTP = action.payload;
        state.isOTPLoading = false;
      })
      .addCase(generateOTP.rejected, (state, action) => {
        state.isOTPLoading = false;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isVerifyOTPLoading = true;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.verifiedOTP = action.payload;
        state.isVerifyOTPLoading = false;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isVerifyOTPLoading = false;
      });
  },
});

export const {
  setOTPTimer
} = OTPSlice.actions;

export default OTPSlice.reducer;
