import "./GenericModal.css";
import { Modal } from "react-bootstrap";
import React from "react";

const GenericModal = ({ isOpen, onClose, children, customClass }) => {
  return (
    // <div className={`modal ${isOpen ? 'open' : ''}`}>
    //   <div className="modal-content">
    //     {children}
    //   </div>
    //   <div className="modal-overlay" onClick={onClose}></div>
    // </div>

    <Modal
      show={isOpen}
      onHide={onClose}
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
      className={`generic-modal ${customClass}`}
    >
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default GenericModal;
