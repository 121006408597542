import React from 'react';
import { useInView } from 'react-intersection-observer';
import '../Home.css';

const LazyVideo = ({ children }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div ref={ref} className='min-w-[260px]'>
            {inView && children}
        </div>
    );
};

export default LazyVideo;
