import "./OTP.css";
import React, { useEffect, useRef, useState } from "react";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateOTP } from "./store";
import { API, subscribeUser, verifyOTP } from "../../API";
import { addMsisdn } from "../../redux/auth";
import { getSubscriberDetails } from "../Subscription/store";
import LoadingModal from "../Subscription/components/LoadingModal";

const OTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const checkUserStore = useSelector((state) => state.CheckUser);
  const [loading, setLoading] = useState(false)
  // const OTPStore = useSelector((state) => state.OTP);
  const [otp, setOTP] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const [error, setError] = useState(false);
  const timerRef = useRef(null);

  const subscribeThisUser = (cellNo) => {
    let data = {
      subMode: "WEBVIEW",
      msisdn: cellNo,
    }
    subscribeUser(data)
      .then(async (res) => {
        // console.log("Subscription res ", res.data)
        const subRes = await dispatch(getSubscriberDetails({ cellno: cellNo })).unwrap();
        // console.log("Subscriber res ", subRes)
      })
      .catch((err) => {
        console.log("Subscription Error: ", err)
        sessionStorage.removeItem("authToken");
        localStorage.removeItem("persist:root");
      })
  }

  const handleOTPChange = otp => {
    if (error) setError(false);
    setOTP(otp);

    if (otp.length === 4) {
      // setIsButtonDisabled(false)
      handleVerifyOTP(otp)
    }
  };

  useEffect(() => {
    // console.log("TTOOKKEENN -> ", token)
    if (timeLeft > 0) {
      setIsResendButtonDisabled(true);
      if (!timerRef.current) {
        timerRef.current = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft <= 1) {
              clearInterval(timerRef.current);
              timerRef.current = null;
              setIsResendButtonDisabled(false);
              return 0;
            }
            return prevTimeLeft - 1;
          });
        }, 1000);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [dispatch, timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  };

  const handleResendOTP = async () => {
    if (checkUserStore?.userMSISDN) {
      dispatch(generateOTP({ cellno: checkUserStore.userMSISDN, token }));
      setIsResendButtonDisabled(true);
      setTimeLeft(60);
    }
  }

  const handleVerifyOTP = async (otp) => {
    if (checkUserStore?.userMSISDN && otp) {
      setLoading(true)
      verifyOTP({ otp, cellno: checkUserStore.userMSISDN })
        .then(async (response) => {
          // console.log("verified", response);
          if (response?.success) {
            sessionStorage.setItem('authToken', response.data.accessToken);
            API.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
            const subRes = await dispatch(getSubscriberDetails({ cellno: checkUserStore?.userMSISDN })).unwrap();
            // console.log("subscriber res ", subRes)
            if (subRes.message === "Subscriber not found.") {
              subscribeThisUser(checkUserStore?.userMSISDN);
            }
            // sessionStorage.setItem("authToken", response?.data?.accessToken);
            // API.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.accessToken}`;
            dispatch(addMsisdn({ msisdn: response?.data?.msisdn }));
            setTimeout(() => {
              setLoading(false)
              navigate("/home");
            }, 1300)
          } else {
            setError(true);
          }
        })

    }
  }


  if (loading) return <div className='min-h-[100vh] bg-[#f8fbea] flex items-center justify-center'>
    <LoadingModal />
  </div>
  return (
    <>
      <div className="otp-page bg-[#f8fbea] h-screen" id="otp-screen">
        <div className="flex items-center justify-between p-3">
          <svg
            onClick={() => navigate(-1)}
            className="cursor-pointer"
            width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#0F2532" />
            <g clipPath="url(#clip0_811_8632)">
              <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_811_8632">
                <rect width="24" height="24" fill="white" transform="translate(8 8)" />
              </clipPath>
            </defs>
          </svg>

          {/* <p className="title">کوڈ درج کریں</p> */}
        </div>

        <div className="otp-container mt-5">
          <div>
            <div className="mt-3 mb-6">
              <p className="text-center text-[20px] font-bold tracking-wider">
                کوڈ کو درج کریں <span>OTP</span> بھیجے گئے
              </p>
              <p className="number digit mt-2">{checkUserStore?.userMSISDN}</p>
            </div>
            <OtpInput
              numInputs={4}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              value={otp}
              onChange={handleOTPChange}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              secure={false}
              inputStyle="inputStyle digit"
              containerStyle="containerStyle"
            />
          </div>

        </div>

        <div className="mt-5 text-center digit font-bold" style={{ color: "#DE0938", fontSize: "14px" }}>{isResendButtonDisabled && formatTime(timeLeft)}</div>

        {
          error ? (
            <p style={{ direction: "rtl", color: "red", textAlign: "center" }}>آپ نے غلط OTP درج کیا ہے۔</p>
          ) : (
            ""
          )
        }

        <div className="footer-section flex items-center justify-center mt-5">
          <button className={`resend-button ${isResendButtonDisabled && "opacity-40"}`} disabled={isResendButtonDisabled} onClick={handleResendOTP}>دوبارہ بھیجیں۔</button>
          <p className="resend-message">کوڈ موصول نہیں ہوا؟</p>
        </div>
        {/* <button onClick={handleVerifyOTP} disabled={otp.length !== 4} className={`mt-2 add-btn ${isButtonDisabled ? "disabled-button" : "active-button"}`}>جمع کرائیں</button> */}
      </div>
    </>
  )
};

export default OTP;
