import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../../API";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

export const HandleCheckSubscriber = createAsyncThunk(
  "CheckUser/HandleCheckSubscriber",
  async ({ msisdn }) => {
    try {
      // console.log("MSISDN:", msisdn)
      const response = await axios.get(`${baseURL}/authentications/generate-token/${msisdn}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      API.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
      return response.data;
    } catch (error) {
      console.error("Error in HandleCheckSubscriber:", error.response ? error.response.data : error.message);
      throw error;
    }
  }
);

export const handleSubscriberAndFetchAuth = createAsyncThunk(
  "CheckUser/handleSubscriberAndFetchAuth",
  async ({ msisdn }, { dispatch }) => {
    try {
      const response = await dispatch(HandleCheckSubscriber({ msisdn })).unwrap();
      const token = response.data;
      if (!token) {
        throw new Error("Token was not retrieved.");
      }
      return token;
    } catch (error) {
      console.error("Error in handleSubscriberAndFetchAuth:", error);
      throw error;
    }
  }
);


const checkUserSlice = createSlice({
  name: "CheckUser",
  initialState: {
    subscriberData: {},
    userMSISDN: "",
    token: "",
    isSubscriber: false,
  },
  reducers: {
    resetCheckUserStore: (state, action) => {
      state.subscriberData = {};
      state.isSubscriber = false;
    },

    saveUserMSISDN: (state, action) => {
      state.userMSISDN = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HandleCheckSubscriber.pending, (state) => {
        state.isSubscriber = true;
      })
      .addCase(HandleCheckSubscriber.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isSubscriber = false;
      })
      .addCase(HandleCheckSubscriber.rejected, (state, action) => {
        state.isSubscriber = false;
      });
  },
});

export const {
  resetCheckUserStore,
  saveUserMSISDN
} = checkUserSlice.actions;

export default checkUserSlice.reducer;
