import React from 'react'
import { formatPriceStr } from '../../../utils/extras'

const Prediction = () => (
    <div className='bg-white w-full'>
        <table className='w-full text-center text-[14px]'>
            <thead>
                <tr className='bg-[#ecf9ff] h-12'>
                    <th>پیشن گوئی</th>
                    <th>یونٹس</th>
                    <th>زیادہ سے زیادہ</th>
                </tr>
            </thead>
            <tbody>
                <tr className='w-full'>
                    <td className='flex items-center justify-center h-[54px] gap-1 bg-[#EEF6D1] '>
                    قیمت بڑھنے کا امکان
                        <img src="/svg/up-arrow.svg" alt="up-arrow" className='mt-2 w-4 h-4' />
                    </td>
                    <td className='w-[33%] pb-2'>من</td>
                    <td className='w-[33%] pt-2'><span className='text-[14px] font-normal'>{formatPriceStr("2400")}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
)

export default Prediction